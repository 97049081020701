<template>
  <div>
    <a-card title="标签配置">
      <a-spin :spinning="loading">
        <a-form :label-col="{ span: 7 }" :wrapper-col="{ span: 16 }" @submit="handleSubmit">
          <a-row>
            <a-col :span="8">
              <a-form-item label="库位标签尺寸">
                <a-select v-model="form.location_label_size" style="width: 100%">
                  <a-select-option value="length_95_width_43">95mm * 43mm</a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
            <a-col :span="8">
              <a-form-item label="产品标签尺寸">
                <a-select v-model="form.material_label_size" style="width: 100%">
                  <a-select-option value="length_95_width_43">95mm * 43mm</a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
            <a-col :span="8">
              <a-form-item label="入库标签尺寸">
                <a-select v-model="form.stock_in_label_size" style="width: 100%">
                  <a-select-option value="length_95_width_43">95mm * 43mm</a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
          </a-row>
        </a-form>
      </a-spin>

      <div style="margin-top: 32px">
        <a-popconfirm title="确定保存吗?" @confirm="create">
          <a-button type="primary" :loading="loading">保存</a-button>
        </a-popconfirm>
      </div>
    </a-card>
  </div>
</template>

<script>
import { labelConfigList, labelConfigUpdate } from "@/api/system";

export default {
  name: "SystemConfigs",
  components: {},
  data() {
    return {
      loading: false,
      visible: false,
      form: {
        location_label_size: undefined,
        material_label_size: undefined,
        stock_in_label_size: undefined,
      },
    };
  },
  methods: {
    initialize() {
      this.list();
    },
    list() {
      this.loading = true;
      labelConfigList()
        .then((data) => {
          this.form = {
            material_label_size: data.material_label_size,
            location_label_size: data.location_label_size,
            stock_in_label_size: data.stock_in_label_size,
          };
        })
        .finally(() => {
          this.loading = false;
        });
    },
    create() {
      this.confirmLoading = true;
      labelConfigUpdate(this.form)
        .then((data) => {
          this.$message.success("保存成功");
          this.$store.commit("setBarConfig", data);
        })
        .finally(() => {
          this.confirmLoading = false;
        });
    },
  },
  mounted() {
    this.initialize();
  },
};
</script>

<style scoped></style>
